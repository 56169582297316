
export enum DEVICE_MODE_TYPE {

  STORE = 0,
  SETUP = 1,
  STREET = 2,
  SURVIVAL = 3,

}

export function getDeviceModeTypes(): { value: number, name: string }[] {

  return Object.keys(DEVICE_MODE_TYPE)
    .filter((key: any) => !isNaN(Number(DEVICE_MODE_TYPE[key])))
    .map((key: any) => {
      return { value: Number(DEVICE_MODE_TYPE[key]), name: 'DEVICE_MODE_TYPE_' + key };
    });

}
