// Angular modules
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


// Models
import {
  DataListOperation,

} from '@models/index';

@Component({
  selector: 'lockbin-bulk-operations',
  templateUrl: './bulk-operations.component.html',
  styleUrls: ['./bulk-operations.component.scss'],
})
export class BulkOperationsComponent implements OnChanges {
  @Input() bulkOperations?: Array<DataListOperation>;
  @Input() numElementsChecked: number = 0;
  @Output() selectedOperation = '';
  @Output() doClick: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public translateService: TranslateService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes);
    // console.log(this.numElementsChecked);
  }

  public onClick(): void {
    this.doClick.emit(this.selectedOperation);
  }

  public clearSelectedOperation() {
    this.selectedOperation = '';
  }

}
